'use client';

import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import type { InventoryResource, SecurityScan } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { useDAL } from '@/data/dal';
import type { BaseAction } from '@/data/inventory/actions';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { isDateValid } from '@/utils/dateTime';
import dayjs, { DATE_FORMAT } from '@/utils/dayjs';

import { getEvidenceTypeLabel } from './securityUtils';
import { SkullIcon } from './skullIcon';

import { Accordion, AccordionDetails, AccordionSummary } from '../accordion';
import ConfirmationDialog from '../dialogs/confirmationDialog';
import { FileLink, isWindowsMachine } from '../explorer/fileLink';
import { StatusCardHeader } from '../panels/instance/tabs/statusCardHeader';
import { SnapshotIndicator } from '../snapshots/SnapshotIndicator';

export interface Evidence {
  evidencetype: EvidenceTypesEnum;
  Count: number;
  Examples: string[];
}

export enum EvidenceTypesEnum {
  suspicious_extension_detected = 'suspicious_extension_detected',
  entropy_change_detected = 'entropy_change_detected',
  encrypted_file_detected = 'encrypted_file_detected',
}

interface SecurityScanCardProps {
  resource: InventoryResource;
  onEntityChange?: () => Promise<void>;
}

export const SecurityScanCard = (props: SecurityScanCardProps) => {
  const { resource, onEntityChange } = props;
  const dal = useDAL();
  const [muteDialogOpen, setMuteDialogOpen] = useState(false);
  const { body: scans, isLoading } = dal.security.scansSummary.get(resource.id);

  if (isLoading) {
    return <Skeleton />;
  }

  if (!scans?.firstInfected) {
    return null;
  }

  if (!scans.lastScan) {
    return null;
  }

  const evidences = JSON.parse(scans.lastScan.summaryJson) as Evidence[];
  const actions: BaseAction[] = [
    {
      title: 'Mute alert',
      execute: () => {
        setMuteDialogOpen(true);
      },
      testId: 'mute-security-alert',
      tooltip: 'Mute all security alerts for this resource',
      icon: 'material-symbols-motion-photos-paused-outline-rounded',
    },
  ];

  let description = `{NUM_OF_RESOURCES_SHOULD_GO_HERE} of this resource’s latest snapshots show  strong ransomware indications`;
  if (isDateValid(scans.lastClean?.createdTime)) {
    description += `, last backed up on ${dayjs.utc(scans.lastClean.createdTime).format(DATE_FORMAT)}`;
  } else {
    description += '.';
  }

  function triggerMuteAlerts(): void | Promise<void> {
    alert('Mute not implemented');
    void onEntityChange?.();
    //.then(onEntityChange);
    //.then(() => setMuteDialogOpen(false));
  }

  return (
    <Box
      padding={'24px 0 12px'}
      border={'1px solid transparent'}
      borderRadius={'16px'}
      sx={{
        background: `linear-gradient(var(--mui-palette-background-paper) 0 0) padding-box,  var(--mui-palette-error-main) border-box`,
      }}
    >
      <Stack gap='24px'>
        <Stack marginBottom='24px' alignItems={'center'} spacing={'12px'}>
          <Box width='100%' padding='0 24px 0 40px'>
            <StatusCardHeader
              title='Ransomware alert'
              actions={actions}
              entity={resource}
            />
          </Box>
          <SkullIcon />
          <Typography className='font-semibold' fontSize={'16px'}>
            Infected snapshots detected
          </Typography>
        </Stack>
        <Stack direction='row' marginX='40px' gap='40px'>
          <Typography width='300px' lineHeight='20px'>
            {description}
          </Typography>
          <Divider orientation='vertical' flexItem />
          <Stack flexGrow={1} gap='12px' justifyContent='space-around'>
            {scans.lastClean && isDateValid(scans.lastClean) ? (
              <SnapshotSummary
                scan={scans.lastClean}
                title='Last clean snapshot'
              />
            ) : (
              <EmptySnapshotSummary title='Last clean snapshot' />
            )}
            {scans.firstInfected && isDateValid(scans.firstInfected) ? (
              <SnapshotSummary
                scan={scans.firstInfected}
                title='First infected snapshot'
              />
            ) : (
              <EmptySnapshotSummary title='First infected snapshot' />
            )}
          </Stack>
        </Stack>
        <Stack marginY='12px' gap='24px'>
          {evidences.map((evidence, idx) => {
            if (!evidence.Examples.length) {
              return null;
            }
            return (
              <Stack gap='12px' key={evidence.evidencetype}>
                {!!idx && <Divider />}
                <EvidenceDetails evidence={evidence} resource={resource} />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <ConfirmationDialog
        isOpen={muteDialogOpen}
        title='Mute ransomware alert'
        description={`Eon won't alert on infected snapshots and ransomware indications for this resource.`}
        onCancel={() => setMuteDialogOpen(false)}
        onConfirm={triggerMuteAlerts}
        buttonTitle='Mute Alert'
      />
    </Box>
  );
};

interface EvidenceDetailsProps {
  evidence: Evidence;
  resource: InventoryResource;
}

const EvidenceDetails = ({ evidence, resource }: EvidenceDetailsProps) => {
  const evidenceLabel = getEvidenceTypeLabel(
    evidence.evidencetype
  )?.evidenceLabel;
  const isWindows = isWindowsMachine(evidence.Examples);
  return (
    <Accordion disableGutters sx={{ marginX: '40px' }}>
      <AccordionSummary>
        <Typography className='font-semibold'>{evidenceLabel}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography marginBottom='16px'>{`${evidence.Count} ${evidenceLabel?.toLowerCase()} indicators were detected. Here are a few samples:`}</Typography>
        <Stack gap='16px'>
          {evidence.Examples.map((example) => (
            <Stack
              direction={'row'}
              gap='12px'
              key={example}
              alignItems='center'
            >
              <SkullIcon />
              <FileLink
                sx={{
                  backgroundColor: 'rgba(240,68,101,0.2)',
                  color: 'var(--mui-palette-error-main)',
                }}
                entity={resource}
                location={example}
                isWindowsMachine={isWindows}
              />
            </Stack>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const SnapshotSummary = (props: { scan: SecurityScan; title: string }) => {
  const { scan, title } = props;

  const dal = useDAL();
  const { data: vaults } = useBackupVaults();
  const { body: snapshot, isLoading } = dal.inventory.snapshots.get(
    scan.snapshotId
  );
  const vault = vaults?.find((v) => v.id === snapshot?.snapshot.vaultId);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Typography flexGrow={1}>{title}</Typography>
      <Stack direction='row' gap='8px' alignItems='center'>
        <SnapshotIndicator backgroundColor={vault?.backgroundColor || null} />
        <Typography>
          {dayjs.utc(scan.createdTime).format(DATE_FORMAT)}
        </Typography>
      </Stack>
    </Stack>
  );
};

const EmptySnapshotSummary = (props: { title: string }) => {
  const { title } = props;
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Typography flexGrow={1}>{title}</Typography>
      <Typography>None</Typography>
    </Stack>
  );
};
