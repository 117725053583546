import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { BackupStatus } from '@repo/api-gw-sdk';
import { useMemo, useState } from 'react';

import { useDAL } from '@/data/dal';
import { ApplicationsLogosWithAliases } from '@/data/inventory/data';
import {
  ListOperator,
  PropertyType,
  StringOperator,
  isValueCondition,
  type Condition,
  type FilterProperty,
} from '@/types/advanceFilter';

import {
  backupStatus,
  controlId,
  controlLevel,
  mutedControlId,
} from './properties';

import {
  BackupStatusFilterRow,
  AdditionalStatuses,
  ViolationsDetectedFilterRow,
} from '../backupStatus/backupStatusFilterRow';
import { Loader } from '../layout/loading';
import { SearchTextBox } from '../search/searchTextBox';
import { CircleImage } from '../shared/circleImage';
import { TransactionalTextField } from '../shared/transactionalTextField';

export interface FilterProps {
  property: FilterProperty;
  conditions: Condition[];
  'data-testid'?: string;
  onChange: (conditions: Condition[]) => void;
  renderFiltersPanel: (expandedPropertyName?: string) => void;
}

export const FreeTextFilter = ({
  placeholder,
  property,
  conditions,
  onChange,
  'data-testid': dataTestId,
}: FilterProps & { placeholder: string }) => {
  const condition = conditions.find(
    (x) => isValueCondition(x) && x.property === property.name
  );

  return (
    <FormControl variant='outlined' fullWidth>
      <TransactionalTextField
        showSearchIcon
        showClearIcon
        placeholder={placeholder}
        allowEmptyValue
        data-testid={dataTestId}
        initValue={(isValueCondition(condition) && condition.value?.[0]) || ''}
        onChange={(value) => {
          const cleanValue = (value || '').trim();
          onChange(
            conditions
              .filter(
                (x) => isValueCondition(x) && x.property !== property.name
              )
              .concat(
                cleanValue
                  ? [
                      {
                        type: 'String' as const,
                        property: property.name,
                        operator: StringOperator.Contains,
                        value: [cleanValue],
                      },
                    ]
                  : []
              )
          );
        }}
      />
    </FormControl>
  );
};

export const MultiSelectFilter = ({
  property,
  conditions,
  onChange,
  options,
  'data-testid': dataTestId,
}: FilterProps & {
  options: {
    id: string;
    name: string;
    Image?: React.ReactNode;
    description?: React.ReactNode;
  }[];
}) => {
  const condition = conditions.find(
    (x) => isValueCondition(x) && x.property === property.name
  );

  const selectedResources =
    (isValueCondition(condition) && condition.value) || [];

  return (
    <FormControl variant='outlined' fullWidth>
      {options.map(({ id, name, Image, description }) => {
        return (
          <FormControlLabel
            key={id}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              width: '100%',
              i: {
                visibility: 'hidden',
              },
              '&:hover i': {
                visibility: 'visible',
              },
            }}
            control={
              <Checkbox
                inputProps={
                  { 'data-testid': `${dataTestId}-${id}` } as Record<
                    string,
                    string
                  >
                }
                className='p-[8px] mr-[4px]'
                size='small'
                checked={selectedResources.includes(id)}
                onChange={(event, value) => {
                  const values = value
                    ? [...selectedResources, id]
                    : selectedResources.filter((x) => x !== id);

                  onChange(
                    conditions
                      .filter(
                        (x) =>
                          isValueCondition(x) && x.property !== property.name
                      )
                      .concat(
                        values.length
                          ? [
                              {
                                type: 'List' as const,
                                property: property.name,
                                operator:
                                  property.type === PropertyType.MultipleValues
                                    ? ListOperator.ContainsAnyOf
                                    : ListOperator.In,
                                value: values,
                              },
                            ]
                          : []
                      )
                  );
                }}
              />
            }
            label={
              <Stack
                direction='row'
                alignItems='start'
                className='mt-[8px]'
                gap='4px'
              >
                {Image}
                <Typography className='mt-[4px] break-all'>{name}</Typography>
                {description && (
                  <Tooltip
                    title={description}
                    slotProps={{
                      tooltip: {
                        sx: {
                          padding: '16px',
                        },
                      },
                    }}
                  >
                    <i className='absolute right-0 material-symbols-info-outline h-[20px] w-[20px] p-[2px] cursor-default' />
                  </Tooltip>
                )}
              </Stack>
            }
          />
        );
      })}
    </FormControl>
  );
};

export const BackupStatusFilter = ({
  conditions,
  onChange,
  renderFiltersPanel,
}: FilterProps) => {
  const valueConditions = conditions.filter(isValueCondition);

  const backupStatusConditions = valueConditions.filter(
    (x) => x.property === backupStatus.name
  );

  const includedBackupStatusCondition = backupStatusConditions.find(
    (x) => x.operator === StringOperator.In
  );

  const excludedBackupStatusCondition = backupStatusConditions.find(
    (x) => x.operator === StringOperator.NotIn
  );

  const controlLevelCondition = valueConditions.find(
    (x) => x.property === controlLevel.name
  );

  const selectedStatuses = includedBackupStatusCondition?.value || [];

  const selectedLevel = controlLevelCondition?.value?.[0] || undefined;

  const toggleBackupStatus = (status: BackupStatus) => {
    const cleanConditions = valueConditions.filter(
      (x) =>
        x !== includedBackupStatusCondition &&
        x.property !== controlLevel.name &&
        x.property !== controlId.name &&
        x.property !== mutedControlId.name
    );

    if (selectedStatuses.includes(status)) {
      const updatedStatuses = selectedStatuses.filter(
        (x) => x !== status.toString()
      );
      onChange(
        cleanConditions.concat(
          updatedStatuses.length === 0
            ? []
            : [
                {
                  type: 'String' as const,
                  property: backupStatus.name,
                  operator: StringOperator.In,
                  value: updatedStatuses,
                },
              ]
        )
      );
    } else {
      onChange(
        cleanConditions.concat({
          type: 'String' as const,
          property: backupStatus.name,
          operator: StringOperator.In,
          value: selectedStatuses.concat([status]),
        })
      );
    }
  };

  const selectControlLevel = (severity: string) => {
    const cleanConditions = valueConditions.filter(
      (x) =>
        x !== includedBackupStatusCondition &&
        x.property !== controlLevel.name &&
        x.property !== controlId.name &&
        x.property !== mutedControlId.name
    );

    onChange(
      cleanConditions.concat({
        type: 'String' as const,
        property: controlLevel.name,
        operator: StringOperator.Equals,
        value: [severity.toUpperCase()],
      })
    );
  };

  return (
    <Stack>
      <BackupStatusFilterRow
        backupStatus={BackupStatus.Protected}
        toggleBackupStatus={toggleBackupStatus}
        selectedBackupStatuses={selectedStatuses}
      />
      <BackupStatusFilterRow
        backupStatus={BackupStatus.AllViolationsMuted}
        toggleBackupStatus={toggleBackupStatus}
        selectedBackupStatuses={selectedStatuses}
      />
      <ViolationsDetectedFilterRow
        backupStatus={BackupStatus.ViolationsDetected}
        toggleBackupStatus={toggleBackupStatus}
        selectControlLevel={selectControlLevel}
        selectedBackupStatuses={selectedStatuses}
        selectedLevel={selectedLevel?.toLowerCase()}
        renderFiltersPanel={renderFiltersPanel}
        conditions={conditions}
        onFilterChange={onChange}
      />
      <Divider sx={{ margin: '12px -24px' }} />
      <BackupStatusFilterRow
        backupStatus={BackupStatus.GenericBackups}
        toggleBackupStatus={toggleBackupStatus}
        selectedBackupStatuses={selectedStatuses}
      />
      <BackupStatusFilterRow
        backupStatus={BackupStatus.NotBackedUp}
        toggleBackupStatus={toggleBackupStatus}
        selectedBackupStatuses={selectedStatuses}
      />
      <BackupStatusFilterRow
        backupStatus={BackupStatus.InitialClassification}
        toggleBackupStatus={toggleBackupStatus}
        selectedBackupStatuses={selectedStatuses}
      />
      <BackupStatusFilterRow
        backupStatus={BackupStatus.Terminated}
        toggleBackupStatus={toggleBackupStatus}
        selectedBackupStatuses={selectedStatuses}
      />
      {!excludedBackupStatusCondition?.value?.includes(
        BackupStatus.Disconnected.toString()
      ) && (
        <BackupStatusFilterRow
          backupStatus={BackupStatus.Disconnected}
          toggleBackupStatus={toggleBackupStatus}
          selectedBackupStatuses={selectedStatuses}
        />
      )}
      {!excludedBackupStatusCondition?.value?.includes(
        BackupStatus.ExcludedFromBackup.toString()
      ) && (
        <BackupStatusFilterRow
          backupStatus={BackupStatus.ExcludedFromBackup}
          toggleBackupStatus={toggleBackupStatus}
          selectedBackupStatuses={selectedStatuses}
        />
      )}
      <Divider sx={{ margin: '12px -24px' }} />
      <AdditionalStatuses
        valueConditions={valueConditions}
        onChange={onChange}
        excludedBackupStatusCondition={excludedBackupStatusCondition}
        includedBackupStatusCondition={includedBackupStatusCondition}
      />
    </Stack>
  );
};

export const AppsFilter = (props: FilterProps) => {
  const dal = useDAL();
  const [filter, setFilter] = useState('');
  const [isScrolledVertically, setScrolledVertically] = useState(false);
  const { body, isLoading } = dal.inventory.apps.list();

  if (isLoading) {
    return <Loader />;
  }

  const condition = props.conditions.find(
    (x) => isValueCondition(x) && x.property === props.property.name
  );

  const selectedResources =
    (isValueCondition(condition) && condition.value) || [];

  return (
    <Stack className='h-full overflow-hidden'>
      <Stack
        className='px-[24px] pb-[24px]'
        sx={{
          borderBottom: '1px solid var(--mui-palette-divider)',
          boxShadow: isScrolledVertically
            ? '0px 10px 30px 0px rgba(0, 0, 0, 0.10)'
            : undefined,
        }}
      >
        <SearchTextBox
          value={filter}
          onChange={setFilter}
          placeholder='Search by app name'
        />
      </Stack>
      <Box
        flexGrow={1}
        className='overflow-auto px-[24px] py-[12px]'
        onScroll={(event) => {
          const target = event.target as HTMLDivElement;
          if (typeof target.scrollTop === 'number') {
            const isScrolled = target.scrollTop > 0;
            if (isScrolled !== isScrolledVertically) {
              setScrolledVertically(isScrolled);
            }
          }
        }}
      >
        <MultiSelectFilter
          {...props}
          options={(body?.apps || [])
            .filter(
              (x) => !filter || x.toLowerCase().includes(filter.toLowerCase())
            )
            .map((x) => ({
              id: x,
              name: x,
              Image: (
                <CircleImage
                  alt={x}
                  src={ApplicationsLogosWithAliases[x]}
                  className='mr-[8px]'
                />
              ),
            }))}
        />
      </Box>
      {!!selectedResources.length && (
        <Stack
          direction='row'
          alignItems='center'
          className='px-[24px] py-[16px]'
          justifyContent='space-between'
          sx={{
            boxShadow: '0px -5px 30px 0px rgba(0, 0, 0, 0.10)',
          }}
        >
          <Typography>{`${selectedResources.length} apps selected`}</Typography>
          <Stack direction='row' alignItems='center'>
            <Tooltip title='Clear all'>
              <IconButton
                onClick={() =>
                  props.onChange(
                    props.conditions.filter((x) => x !== condition)
                  )
                }
              >
                <i className='material-symbols-filter-alt-off-outline-rounded text-textPrimary h-[20px] w-[20px]'></i>
              </IconButton>
            </Tooltip>
            <Button
              className='ml-[12px]'
              variant='outlined'
              onClick={() => props.renderFiltersPanel()}
            >
              Done
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export const TagsFilter = (props: FilterProps) => {
  const dal = useDAL();
  const [keyFilter, setKeyFilter] = useState('');
  const [valueFilter, setValueFilter] = useState('');
  const [filterMode, setFilterMode] = useState<'key' | 'value' | 'key-value'>(
    'key-value'
  );
  const [isScrolledVertically, setScrolledVertically] = useState(false);
  const { body, isLoading } = dal.inventory.tags.list();

  const condition = props.conditions.find(
    (x) => isValueCondition(x) && x.property === props.property.name
  );

  const selectedResources =
    (isValueCondition(condition) && condition.value) || [];

  const tagsReduced = useMemo(
    () =>
      (body?.tags || []).reduce(
        (acc, tag) => {
          {
            acc.keys.add(tag.key);
            if (tag.value) {
              acc.values.add(tag.value);
            }
            return acc;
          }
        },
        { keys: new Set<string>(), values: new Set<string>() }
      ),
    [body]
  );

  const keys = useMemo(
    () =>
      Array.from(tagsReduced.keys)
        .filter(
          (key) =>
            !keyFilter || key.toLowerCase().includes(keyFilter.toLowerCase())
        )
        .map((key) => ({
          id: key,
          name: key,
        })),
    [keyFilter, tagsReduced.keys]
  );

  const values = useMemo(
    () =>
      Array.from(tagsReduced.values)
        .filter(
          (value) =>
            !valueFilter ||
            value.toLowerCase().includes(valueFilter.toLowerCase())
        )
        .sort()
        .map((value) => ({
          id: value,
          name: value,
        })),
    [valueFilter, tagsReduced.values]
  );

  const keyValuePairs = useMemo(
    () =>
      (body?.tags || [])
        .filter(
          (t) =>
            (!keyFilter ||
              t.key.toLowerCase().includes(keyFilter.toLowerCase())) &&
            (!valueFilter ||
              !!t.value?.toLowerCase().includes(valueFilter.toLowerCase()))
        )
        .map((x) => {
          const value = `${x.key}=${x.value || ''}`;
          return {
            id: value,
            name: value,
          };
        }),
    [keyFilter, valueFilter, body]
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Stack className='h-full overflow-hidden'>
      <Stack
        gap='12px'
        className='px-[24px] pb-[24px] py-[12px]'
        sx={{
          borderBottom: '1px solid var(--mui-palette-divider)',
          boxShadow: isScrolledVertically
            ? '0px 10px 30px 0px rgba(0, 0, 0, 0.10)'
            : undefined,
        }}
      >
        <Typography>Filter by</Typography>
        <Select
          value={filterMode}
          onChange={(event) => setFilterMode(event.target.value as never)}
        >
          <MenuItem value='key'>Tag keys</MenuItem>
          {/* <MenuItem value='value'>Tag values</MenuItem> */}
          <MenuItem value='key-value'>Key-value pairs</MenuItem>
        </Select>
        <Typography className='my-[12px]'>
          {`To filter your resources, select one or more ${filterMode}${filterMode === 'key' || filterMode === 'value' ? 's' : ' pairs'}`}
        </Typography>
        {filterMode.includes('key') && (
          <SearchTextBox
            value={keyFilter}
            onChange={setKeyFilter}
            placeholder='Search by key'
          />
        )}
        {filterMode.includes('value') && (
          <SearchTextBox
            value={valueFilter}
            onChange={setValueFilter}
            placeholder='Search by value'
          />
        )}
      </Stack>
      <Box
        flexGrow={1}
        className='overflow-auto px-[24px] py-[12px]'
        onScroll={(event) => {
          const target = event.target as HTMLDivElement;
          if (typeof target.scrollTop === 'number') {
            const isScrolled = target.scrollTop > 0;
            if (isScrolled !== isScrolledVertically) {
              setScrolledVertically(isScrolled);
            }
          }
        }}
      >
        <MultiSelectFilter
          {...props}
          options={
            filterMode === 'key'
              ? keys
              : filterMode === 'value'
                ? values
                : keyValuePairs
          }
        />
      </Box>
      {!!selectedResources.length && (
        <Stack
          direction='row'
          alignItems='center'
          className='px-[24px] py-[16px]'
          justifyContent='space-between'
          sx={{
            boxShadow: '0px -5px 30px 0px rgba(0, 0, 0, 0.10)',
          }}
        >
          <Typography>{`${selectedResources.length} tags selected`}</Typography>
          <Stack direction='row' alignItems='center'>
            <Tooltip title='Clear all'>
              <IconButton
                onClick={() =>
                  props.onChange(
                    props.conditions.filter((x) => x !== condition)
                  )
                }
              >
                <i className='material-symbols-filter-alt-off-outline-rounded text-textPrimary h-[20px] w-[20px]'></i>
              </IconButton>
            </Tooltip>
            <Button
              className='ml-[12px]'
              variant='outlined'
              onClick={() => props.renderFiltersPanel()}
            >
              Done
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
