import cronstrue from 'cronstrue';

export const frequencyCronText = (cron: string) => {
  if (cron === '0 0 * * *') {
    return 'Daily';
  } else if (cron === '0 0 * * 0') {
    return 'Weekly';
  } else if (cron === '0 0 1 * *') {
    return 'Monthly';
  } else if (cron === '0 0 1 1 *') {
    return 'Yearly';
  }

  return cronstrue.toString(cron, {
    verbose: true,
  });
};
