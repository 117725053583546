import { Stack, Typography } from '@mui/material';
import type { BackupJob, InventoryResource } from '@repo/api-gw-sdk';
import type { Row } from '@tanstack/react-table';

import { useFeatureFlags } from '@/contexts/useFeatureFlags';

import { isJob } from './properties';

import { isHaveSecurityIssue } from '../security/securityUtils';
import { SkullIcon } from '../security/skullIcon';

interface ResourceNameCellProps {
  row: Row<InventoryResource | BackupJob>;
}

export const ResourceNameCell = ({ row }: ResourceNameCellProps) => {
  const { securityScan } = useFeatureFlags();

  if (isJob(row.original)) {
    return (
      row.original.jobExecutionDetails.resourceDetails?.resourceName && (
        <Typography>
          {row.original.jobExecutionDetails.resourceDetails.resourceName}
        </Typography>
      )
    );
  }

  if (securityScan && isHaveSecurityIssue(row.original)) {
    return (
      <Stack alignItems='center' direction='row' gap='8px'>
        <SkullIcon />
        <Typography
          color='var(--mui-palette-error-main)'
          sx={{ backgroundColor: 'rgba(240,68,101,0.2)' }}
        >
          {row.original.resourceName}
        </Typography>
      </Stack>
    );
  }

  return (
    row.original.resourceName && (
      <Typography>{row.original.resourceName}</Typography>
    )
  );
};
