import {
  type Snapshot,
  SecurityScanConclusionEnum,
  type InventoryResource,
} from '@repo/api-gw-sdk';

import { EvidenceTypesEnum } from './securityScanCard';

export const isHaveSecurityIssue = (entity: InventoryResource | Snapshot) => {
  return (
    entity.securityScan?.conclusion &&
    entity.securityScan.conclusion !== SecurityScanConclusionEnum.Clean
  );
};

export const isSecurityAlertsMuted = (resource: InventoryResource) => {
  // todo: remove "any" once muted really exists
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (resource.securityScan as any)?.muted;
};

const evidenceTypeLabels: {
  type: EvidenceTypesEnum;
  evidenceLabel: string;
  countLabel: string;
}[] = [
  {
    type: EvidenceTypesEnum.suspicious_extension_detected,
    evidenceLabel: 'Suspicious extensions',
    countLabel: 'Suspicious files',
  },
  {
    type: EvidenceTypesEnum.entropy_change_detected,
    evidenceLabel: 'Entropy changes',
    countLabel: 'Encrypted files',
  },
  {
    type: EvidenceTypesEnum.encrypted_file_detected,
    evidenceLabel: 'Encrypted file detected',
    countLabel: 'Encrypted files',
  },
];

export const getEvidenceTypeLabel = (type: EvidenceTypesEnum) => {
  return evidenceTypeLabels.find((e) => e.type === type);
};
