import { Typography, type SxProps } from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import classNames from 'classnames';

import { useRoles } from '@/contexts/useRoles';
import { useRouting } from '@/contexts/useRouting';

function trimLocation(location: string): string {
  // Trims the location to 45 characters, puts ellipsis in the middle
  if (location.length <= 45) {
    return location;
  }
  return `${location.slice(0, 20)}...${location.slice(-25)}`;
}

export function FileLink({
  entity,
  location,
  isWindowsMachine,
  sx,
}: {
  entity: InventoryResource;
  location: string;
  isWindowsMachine: boolean;
  sx?: SxProps;
}) {
  const { isAuthorizedResource } = useRoles();
  const routing = useRouting();
  if (isWindowsMachine) {
    if (location.startsWith('/')) {
      location = location.substring(1);
    }

    location = location.replace(/\//g, '\\');
  }

  // Get directory name
  const sep = isWindowsMachine ? '\\' : '/';
  const dirName = location.substring(0, location.lastIndexOf(sep));

  return (
    <Typography
      sx={sx}
      data-testid='file-link'
      className={classNames([
        'font-semibold',
        {
          'cursor-pointer': isAuthorizedResource('read:files', entity),
        },
      ])}
      onClick={() => {
        if (isAuthorizedResource('read:files', entity)) {
          routing.push(
            `/explorer?resourceId=${encodeURIComponent(entity.id)}&path=${encodeURIComponent(dirName)}&file=${encodeURIComponent(location)}`
          );
        }
      }}
    >
      {trimLocation(location) ?? 'Unknown'}
    </Typography>
  );
}

export const isWindowsMachine = (examples: string[]) =>
  examples
    .filter(Boolean)
    .some((example) =>
      ['/C:/', '/D:/', '/E:/', 'C:/', 'D:/', 'E:/'].some((prefix) =>
        example.toUpperCase()?.startsWith(prefix)
      )
    );
