import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  type SelectProps,
} from '@mui/material';
import { type StaticImageData } from 'next/image';
import { Fragment } from 'react';

import SelectPlaceholder from '../selectPlaceholder';
import { CircleImage } from '../shared/circleImage';

type MultiSelectProps = SelectProps & {
  showImagesInRender?: boolean;
  options: {
    value: string;
    label: React.ReactNode;
    image?: StaticImageData;
  }[];
};

export const MultiSelect = (props: MultiSelectProps) => {
  const { options, showImagesInRender, ...rest } = props;

  return (
    <Select
      {...rest}
      displayEmpty
      renderValue={(selected) => {
        const opts = (Array.isArray(selected) ? selected : [selected])
          .map((opt) => options.find((o) => o.value === opt)!)
          .filter(Boolean);

        if (opts.length === 0) {
          return <SelectPlaceholder />;
        }

        return opts.map((option, i) =>
          showImagesInRender && option.image ? (
            <Tooltip key={option.value} title={option.label as string}>
              <>
                <CircleImage
                  className='mr-[8px]'
                  style={{ display: 'inline' }}
                  alt={option.value}
                  src={option.image}
                  height={30}
                />
              </>
            </Tooltip>
          ) : (
            <Fragment key={option.value}>
              {option.label}
              {i < opts.length - 1 ? ', ' : null}
            </Fragment>
          )
        );
      }}
    >
      {options.map((option) => {
        const isChecked = (props.value as string[]).includes(option.value);
        return (
          <MenuItem key={option.value} value={option.value}>
            {props.multiple && (
              <Checkbox className='mr-[8px]' checked={isChecked} />
            )}
            {option.image && (
              <>
                <CircleImage
                  contrast={isChecked}
                  alt={option.value}
                  src={option.image}
                  className='mr-[8px]'
                  height={30}
                />
              </>
            )}
            <ListItemText primary={option.label} />
          </MenuItem>
        );
      })}
    </Select>
  );
};
